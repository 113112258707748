/**
 * AbortablePromise: A quick & dirty wrapper for JavaScript's Promise class that allows the underlying
 * asynchronous operation to be cancelled. It is only meant for simple situations where no complex promise
 * chaining or merging occurs. It needs a significant amount of work to truly replicate the full
 * functionality of JavaScript's Promise class. Look at Util.fetchWithProgress() for example usage.
 *
 * This class was primarily added to allow splat scene downloads to be cancelled. It has not been tested
 * very thoroughly and the implementation is kinda janky. If you can at all help it, please avoid using it :)
 */
export class AbortablePromise {
  constructor(promiseFunc, abortHandler) {
    let promiseResolve;
    let promiseReject;
    this.promise = new Promise((resolve, reject) => {
      promiseResolve = resolve.bind(this);
      promiseReject = reject.bind(this);
    });

    const resolve = (...args) => {
      promiseResolve(...args);
    };

    const reject = (error) => {
      promiseReject(error);
    };

    promiseFunc(resolve.bind(this), reject.bind(this));
    this.abortHandler = abortHandler;
  }

  then(onResolve) {
    return new AbortablePromise((resolve, reject) => {
      this.promise = this.promise
        .then((...args) => {
          const onResolveResult = onResolve(...args);
          if (
            onResolveResult instanceof Promise ||
            onResolveResult instanceof AbortablePromise
          ) {
            onResolveResult.then((...args2) => {
              resolve(...args2);
            });
          } else {
            resolve(onResolveResult);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    }, this.abortHandler);
  }

  catch(onFail) {
    return new AbortablePromise((resolve) => {
      this.promise = this.promise
        .then((...args) => {
          resolve(...args);
        })
        .catch(onFail);
    }, this.abortHandler);
  }

  abort() {
    if (this.abortHandler) this.abortHandler();
  }

  static resolve(data) {
    return new AbortablePromise((resolve) => {
      resolve(data);
    });
  }

  static reject(error) {
    return new AbortablePromise((resolve, reject) => {
      reject(error);
    });
  }
}
